import React from "react";
import { useEffect } from "react";
import styled from "styled-components";
import { getPhotos } from "../services/Photos";
import { PhotoDisplay } from "../components/PhotoDisplay";
import { Link } from "react-router-dom";

const BarHeight = 40;

const Bar = styled.div`
  height: ${BarHeight}px;
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 10px;
  position: fixed;
  box-sizing: border-box;
  width: 100%;
`;
export const Photos = () => {
  const [photos, setPhotos] = React.useState<any>([]);
  const [error, setError] = React.useState();

  useEffect(() => {
    getPhotos()
      .then((res) => {
        setPhotos(res);
      })
      .catch((err) => {
        setError(err);
      });
  }, []);

  return (
    <>
      <PhotoDisplay
        photos={photos}
        targetHeight={window.innerHeight - BarHeight}
      />
      {error && <p>Error retrieving photos :(</p>}
      <Bar>
        <Link to="/home">Back</Link>
      </Bar>
    </>
  );
};
