import React from "react";
import styled from "styled-components";

const Photo = styled.div<{
  src: string;
  width: number;
  height: number;
  targetHeight: number;
}>`
  background-image: url(${(props: any) => props.src});
  width: ${(props: any) => (props.targetHeight / props.height) * props.width}px;
`;

const Container = styled.div<{ height: number }>`
  display: flex;
  flex-direction: row;
  height: ${(props: any) => props.height}px;
  div {
    background-size: cover;
    flex-shrink: 0;
    height: ${(props: any) => props.height}px;
  }
`;

const Loading = styled.div`
display: flex;
align-items: center;
justify-content: center;
width: 100%;
`
export const PhotoDisplay = ({ photos, targetHeight }: any) => {
  return (
    <Container height={targetHeight}>
      {!photos.length && <Loading>Loading...</Loading>}
      {photos &&
        photos.length &&
        photos.map((photo: any, index: number) => (
          <Photo
            key={index}
            src={photo.url_l}
            width={photo.width_l}
            height={photo.height_l}
            targetHeight={targetHeight}
          ></Photo>
        ))}
    </Container>
  );
};
