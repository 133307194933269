import React, { useState } from "react";
import { ThemeProvider } from "styled-components";
import { GlobalStyles } from "./components/GlobalStyles";
import { lightTheme, darkTheme } from "./components/Theme";
import { useModeSelector } from "use-light-switch";
import { Photos } from "./pages/Photos";
import { Home } from "./pages/Home";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import "./App.css";

function App() {
  const theme = useModeSelector({
    light: lightTheme,
    dark: darkTheme,
    unset: lightTheme,
  });

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <div className="App">
        <Router>
          <Switch>
            <Route path="/images">
              <Photos />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;
