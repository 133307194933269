import { Hero } from "../components/Hero";
import React from "react";
import {
  Link
} from "react-router-dom";
export const Home = () => (
  <Hero>
    <h1>Maxwell Hammad</h1>
    <p>
      I'm an American technologist living in Brooklyn, NY. I currently work at{" "}
      <a href="https://auth0.com">Auth0</a>, building software to help companies
      securely add login to their applications. I'm also the co-founder
      of <a href="https://connectreport.com">ConnectReport</a>, a tool that
      helps companies automatically generate large volumes of reports on their business data. I take <Link to="/images">analog
      photos</Link> when I want to get away from work and run a small photo lab in my
      apartment.
    </p>
  </Hero>
);
