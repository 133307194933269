export const darkTheme = {
  body: '#ffffff',
  text: '#ffffff',
  toggleBorder: '#FFF',
  background: '#0c1138',
  link: "#ff9423"
}
export const lightTheme = {
  body: '#0c1138',
  text: '#0c1138',
  toggleBorder: '#FFF',
  background: '#fff',
  link: "#ff9423"
}