import { createGlobalStyle} from "styled-components"
export const GlobalStyles = createGlobalStyle`
body {
    background: ${({ theme }: { theme: any }) => theme.background};
    color: ${({ theme }: { theme: any }) => theme.body};
    font-family: sofia-pro, sans-serif;
    transition: all 0.50s linear;
    h1, h2, h3, h4, h5, h6 {
      color: ${({ theme }: { theme: any }) => theme.text};
    }
    a {
      color: ${({ theme }: { theme: any }) => theme.link};
    }
  }
`