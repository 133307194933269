import styled from "styled-components";

export const Hero = styled.div`
  p {
    font-size: 4.3vh
  }
  margin: 50px;
  @media (max-width: 500px) {
    margin: 20px;
    h1 {
      font-size: 22px;
    }
    p {
      font-size: 22px;
    }
  }
`